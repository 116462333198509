/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import Main from "./routes/main/Main";
import City from "./routes/city/City";
import Loader from "./components/layout/Loader";
import useAxios from "./shared/hooks/use-axios";
import { url } from "./shared/utils/url";
import { getApp } from "./shared/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "./shared/store/data-Slice";
import { authActions } from "./shared/store/auth-Slice";
import { Helmet } from 'react-helmet';
// import { uiActions } from "./shared/store/ui-Slice";

const MainRouter = () => {
    const lang: string = useSelector((state: any) => state.ui.lang);
    const theme: any = useSelector((state: any) => state.ui.theme);
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    let [loader, setLoader] = useState<any>(true);
    const [tenantData, setTanantData] = useState<any[]>([]);
    const Components: any = !loader && getApp(tenantData);
    const dispatch = useDispatch();

    const handleLogoutApi = async () => {
        try {
            const res: any = await useAxios.post(`/${lang + url?.logout}`);
            if (res && res?.status === 200) {
                return true;
            }
        } catch (error) {
            return false;
            console.error("error while logout", error);
        }
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            let session: any = JSON.parse(localStorage.getItem("sessionTimeout") as any);
            if (!!session && session > new Date().getTime()) {
                const success: any = await handleLogoutApi();
                dispatch(authActions.userDetail(null))
                dispatch(authActions.logout());
            }
        }, 3600000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }

        !!theme && localStorage.setItem("theme", theme);
    }, [theme]);

    const getCityData = async () => {
        try {
            dispatch(dataActions.setIsLoading(true));
            const res: any = await useAxios.get(`/en${url?.city}`);

            if (res && res?.status === 200) {
                // for tenant setting
                let tenant: any = "";
                let arr: any[] = [];
                let cityObj: any = {};
                let helpLineObj: any = {};
                // let colorObj: any = {};
                for (let i = 0; i < res?.data?.length; i++) {
                    const el = res?.data[i];
                    if (window.location.hostname?.includes(el?.code)) {
                        tenant = window.location.hostname?.replace(`${el?.code}.`, "")
                    }

                    let obj: any;
                    if (!!tenant) {
                        obj = {
                            subdomain: el?.code + "." + tenant,
                            app: City,
                            main: false
                        }
                    } else {
                        obj = {
                            subdomain: el?.code + "." + window.location.hostname,
                            app: City,
                            main: false
                        }
                    }

                    helpLineObj[el.name.toLowerCase()] = el?.emergency_nums

                    // colorObj[el.name.toLowerCase()] = {
                    //     "primary": el?.primary_color,
                    //     "secondary": el?.secondary_color,
                    //     "textPrimary": `text-[${el?.primary_color}]`,
                    //     "borderPrimary": `border-[${el?.primary_color}]`,
                    //     "bgPrimary": `bg-[${el?.primary_color}]`,
                    //     "afterBgPrimary": `after:bg-[${el?.primary_color}]`,
                    //     "hoverTextPrimary": `hover:text-[${el?.primary_color}]`,
                    //     "groupHoverTextPrimary": `group-hover:text-[${el?.primary_color}]`,
                    //     "hoverBgPrimary": `hover:bg-[${el?.primary_color}]`,
                    //     "groupHoverBgPrimary": `group-hover:bg-[${el?.primary_color}]`,
                    //     "groupHoverBgSecondary": `group-hover:bg-[${el?.secondary_color}]`,
                    //     "fillPrimary": `fill-[${el?.primary_color}]`,
                    //     "strokePrimary": `stroke-[${el?.primary_color}]`
                    // }


                    if (!cityObj?.hasOwnProperty(el?.code)) {
                        cityObj[el.name.toLowerCase()] = el?.id
                    }
                    arr.push(obj)
                }
                arr.push({
                    subdomain: !!tenant ? tenant : window.location.hostname,
                    app: Main,
                    main: true
                })

                setTanantData(arr);
                // dispatch(uiActions.setColor(colorObj))
                setLoader(false);

                // for city name wise id get
                dispatch(dataActions.setCities(cityObj));
                dispatch(dataActions.setHelpLine(helpLineObj));
                dispatch(dataActions.setIsLoading(false));
            }
        } catch (error) {
            dispatch(dataActions.setIsLoading(false));
            console.error("error while feching city data", error);
        }
    };

    useEffect(() => {
        getCityData()
    }, [])

    return (
        <>
            {!!currentCity && <Helmet>
                <title>Uttar Pradesh Metro Rail Corporation - {(currentCity?.[0]?.toUpperCase() + currentCity?.slice(1))} Metro (UPMRC)</title>
                <meta name="description" content={`Official website of UPMRC for ${(currentCity?.[0]?.toUpperCase() + currentCity?.slice(1))} Metro`} />
                <meta name="keywords" content={`${(currentCity?.[0]?.toUpperCase() + currentCity?.slice(1))} Metro`} />
            </Helmet>}
            <Suspense fallback={<Loader />}>
                {!loader &&
                    <Router>
                        <Components />
                    </Router>
                }
            </Suspense>
        </>
    );
};

export default MainRouter;