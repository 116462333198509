/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { MenuArrow } from '../svg/AllSvg';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { url } from '../../shared/utils/url';
import useAxios from '../../shared/hooks/use-axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { dataActions } from '../../shared/store/data-Slice';
import { uiActions } from '../../shared/store/ui-Slice';

const Header = () => {
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const color: any = useSelector((state: any) => state.ui.color);
    const page: string = useSelector((state: any) => state.ui.page);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [menu, setMenu] = useState<any>(null);
    const [select, setSelect] = useState<any>(null);
    const [selectTitle, setSelectTitle] = useState<any>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const getMenu = async () => {
        try {
            dispatch(dataActions.setIsLoading(true));
            let apiUrl: any;
            if (!!currentCity) {
                apiUrl = `/${lang + url?.menu + currentCity}/`
            } else {
                apiUrl = `/${lang + url?.menu}main-menu`
            }
            if (!!apiUrl) {
                const res: any = await useAxios.get(apiUrl);

                if (res && res?.status === 200) {
                    setData(res?.data)
                }
            }
        } catch (error) {
            console.error("error while feching menu data", error);
        }
    }

    useEffect(() => {
        !!lang && getMenu();
    }, [lang, currentCity])

    useEffect(() => {
        if (data?.length > 0) {
            let route: any;
            let obj: any = { mainmenu: "", submenu: "" }
            if (!!params?.lang) {
                if (window.location.pathname === `/${lang}`) {
                    route = window.location.pathname?.replace(`/${lang}`, "/");
                } else {
                    route = window.location.pathname?.replace(`/${lang}/`, "/");
                }
            } else {
                route = window.location.pathname;
            }
            for (let i = 0; i < data?.length; i++) {
                const el = data[i];
                let mainUrl: any = el?.url?.replace(`/${lang}/`, "");
                if (route?.includes(mainUrl)) {
                    obj.mainmenu = el?.title
                    dispatch(uiActions.setPage(el?.title))
                    if(route !== "/" && (obj.mainmenu === "Home" || obj.mainmenu === "होम")) {
                        obj.mainmenu = ''
                        dispatch(uiActions.setPage(''))
                    }
                }

                for (let j = 0; j < el?.subRoutes?.length; j++) {
                    const s = el?.subRoutes[j];
                    let subUrl: any = s?.url?.replace(`/${lang}/`, "");
                    if (route?.includes(subUrl)) {
                        obj.mainmenu = el?.title
                        obj.submenu = s?.title
                        dispatch(uiActions.setPage(el?.title))
                    }
                }
            }

            setMenu(obj)
        }
    }, [data, window.location.pathname])

    const handleMainPage = () => {
        if (!!currentCity) {
            let tenant: any = window.location.origin?.replace(`${currentCity}.`, "")
            window.location.href = !!params?.lang ? `${tenant}/${params?.lang}` : `${tenant}/`;
        } else {
            !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
        }
    }

    const handleMainPageNavigate = (e: any) => {
        if (e?.key === "Enter") {
            if (!!currentCity) {
                let tenant: any = window.location.origin?.replace(`${currentCity}.`, "")
                window.location.href = !!params?.lang ? `${tenant}/${params?.lang}` : `${tenant}/`;
            } else {
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }
    }

    useEffect(() => {
        if (sidebarOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [sidebarOpen]);

    const handleOpen = (e: any, index: any, length: any) => {
        if (e?.key === "Tab") {
            setSelect(index)
        }
    }

    const handleSubOpen = (e: any, index: any) => {
        if (e?.key === "Tab") {
            !index && setSelect(null)
        }
    }

    const handleLink = (url: any) => {
        dispatch(uiActions.setWebOpen(true));
        dispatch(dataActions.setLink(url));
    }

    return (
        <>
            {/* Backdrop */}
            <div>
                <span
                    id="menu-remove"
                    className={`fixed top-0 left-0 z-20 ${sidebarOpen ? "block" : "hidden"
                        } h-screen w-full bg-[#00000080] lgl:hidden`}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                ></span>
            </div>

            {/* mask bg */}
            <img src={!!!currentCity ? require("../../assets/images/Mask group.png") : (!!currentCity ? require(`../../assets/images/${currentCity}/pattern1.png`) : "")} alt="pattern" className='w-full object-cover h-[12px] lg:h-[20px]' />

            {/* header */}
            <header className="h-[70px] sm:h-[86px] w-full flex items-center justify-between pl-[10px] pr-[20px] xxl:px-[30px] 4xl:pl-[45px] 4xl:pr-[55px] border-b border-secondary border-opacity-[0.2] relative z-[5] dark:border-white dark:bg-[#272727]">
                <Link to={`${!!params?.lang ? `/${params?.lang}` : "/"}`} onClick={() => handleMainPage()} onKeyDown={(e: any) => handleMainPageNavigate(e)} className='focus-within:outline-none focus:outline-none dark:focus:bg-[#000] focus:bg-[#f4f4f4] h-full flex items-center justify-center rounded-[15px] px-[10px]' onFocus={() => dispatch(dataActions.setProfile(false))}>
                    <img src={localStorage.getItem('theme') && localStorage.getItem('theme') === "dark" ? require("../../assets/images/logo-white.png") : require("../../assets/images/logo.png")} alt="Uttar Pradesh Metro Rail Corporation Limited" className='cursor-pointer' onClick={() => handleMainPage()} />
                </Link>

                <nav className='flex items-center h-full'>
                    <ul className="hidden lgl:flex items-center h-full gap-[6px] 2.5xl:gap-[17px] ">
                        {Array.isArray(data) && data.map((item: any, index: number) => (
                            <li className={`px-[4px] lgl:px-[8px] menu flex items-center justify-center gap-[4px] text-sm xl:text-base cursor-pointer h-full ${(menu?.mainmenu === item?.title || select === index) ? `${!!currentCity ? `${color[currentCity]?.textPrimary}` : "dark:text-white text-primary"}` : "dark:text-white text-secondary"} ${!!currentCity ? `${color[currentCity]?.hoverTextPrimary}` : "hover:text-primary"} relative cursor-pointer h-full after:content-[''] after:absolute after:h-[4px] after:hover:w-full after:duration-300 after:left-0 after:bottom-0 ${(menu?.mainmenu === item?.title || select === index) ? "after:w-full" : "after:w-0"} ${!!currentCity ? `${color[currentCity]?.afterBgPrimary}` : "after:bg-primary"} after:rounded-[2px] font-semibold`} key={index} onMouseEnter={() => setSelect(index)} onMouseLeave={() => setSelect(null)}>
                                <Link className='focus-within:outline-none focus:outline-none h-full flex items-center justify-center' to={!!params?.lang ? `/${params?.lang + item?.url?.replace(`/${lang}/`, "")}` : `${item?.url?.replace(`/${lang}/`, "")}`} onKeyDown={(e: any) => handleOpen(e, index, item?.subRoutes?.length)} onClick={(e: any) => item?.subRoutes?.length > 0 && e.preventDefault()} onFocus={() => setSelect(index)} onBlur={() => setSelect(null)}>{item?.title}
                                    {(item?.subRoutes?.length > 0) &&
                                        <ul className={`absolute top-[85px] min-w-[250px] rounded-[10px] dark:bg-black bg-white shadow-[0px_8px_24px_-4px_rgba(24,39,75,0.08),0px_6px_12px_-6px_rgba(24,39,75,0.12)] ${select === index ? "max-h-[300px]" : "max-h-0"} overflow-y-auto submenu ${((data?.length === index + 1) || (data?.length === index + 2)) ? "right-0" : "left-0"}`}>
                                            {Array.isArray(item?.subRoutes) && item?.subRoutes?.map((i: any, idx: number) => (
                                                <li key={idx} className={`${(menu?.submenu === i?.title || page === i?.title || selectTitle === i?.title) ? `${!!currentCity ? `text-white ${color[currentCity]?.bgPrimary} dark:!bg-[#ffff00] dark:*:!text-black` : "bg-primary dark:!bg-[#ffff00] text-white"}` : "dark:text-white text-secondary"} flex items-center justify-start text-sm ${!!currentCity ? color[currentCity]?.hoverBgPrimary : "hover:bg-primary"} hover:text-white ${idx === 0 && "rounded-t-[10px] hover:rounded-t-[10px]"} font-semibold w-full`} onKeyDown={(e: any) => handleSubOpen(e, item?.subRoutes?.length === idx + 1 ? false : true)}>
                                                    {i?.url_type === "external" ?
                                                        <Link to={`${i?.url?.replace(`/${lang}/`, "")}`} target='_blank' onFocus={() => setSelectTitle(i?.title)} onBlur={() => setSelectTitle(null)} className='focus-within:outline-none focus:outline-none w-full h-full py-[12px] flex items-center justify-start px-[23px]' onClick={() => handleLink(i?.url?.replace(`/${lang}/`, ""))}>
                                                            {i?.title}
                                                        </Link>
                                                        : <Link to={!!params?.lang ? `/${params?.lang + i?.url?.replace(`/${lang}/`, "")}` : `${i?.url?.replace(`/${lang}/`, "")}`} onFocus={() => setSelectTitle(i?.title)} onBlur={() => setSelectTitle(null)} className='focus-within:outline-none focus:outline-none w-full h-full py-[12px] flex items-center justify-start px-[23px]'>
                                                            {i?.title}
                                                        </Link>}
                                                </li>
                                            ))}
                                            <img src={!!currentCity ? require(`../../assets/images/${currentCity}/pattern14.png`) : require(`../../assets/images/Mask group24.png`)} alt="pattern" className='rounded-b-[10px] h-[13px] object-cover' />
                                        </ul>
                                    }
                                </Link>
                                {item?.subRoutes?.length > 0 &&
                                    <div className="caret">
                                        <MenuArrow className="dark:fill-white fill-secondary w-[12px] h-[12px] xl:w-[14px] xl:h-[14px]" />
                                    </div>
                                }
                            </li>
                        ))}
                    </ul>

                    <div className='relative lgl:hidden rounded-[10px] !w-[35px] !h-[35px] flex items-center justify-center cursor-pointer border border-[#CDCDCD] dark:border-white !shadow-[0px_1.6484779119491577px_4.121194839477539px_0px_rgba(38,51,77,0.03)]' onClick={() => setSidebarOpen(true)}>
                        <div className='w-full h-full flex flex-col gap-[2px] items-center justify-center absolute top-0 left-0 right-0 bottom-0'>
                            <span className={`${!!currentCity ? `${color[currentCity]?.bgPrimary}` : "bg-primary"} dark:!bg-[#ffff00] opacity-[0.8] w-[18px] h-[4px] rounded-[1px]`}></span>
                            <span className={`${!!currentCity ? `${color[currentCity]?.bgPrimary}` : "bg-primary"} dark:!bg-[#ffff00] opacity-[0.8] w-[18px] h-[4px] rounded-[1px]`}></span>
                            <span className={`${!!currentCity ? `${color[currentCity]?.bgPrimary}` : "bg-primary"} dark:!bg-[#ffff00] opacity-[0.8] w-[18px] h-[4px] rounded-[1px]`}></span>
                        </div>
                    </div>
                </nav>

            </header>

            {/* Mobile Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} menu={data} selectedMenu={menu} />
        </>
    );
};

export default Header;