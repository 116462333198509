/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { dataActions } from "../../shared/store/data-Slice";
import { useDispatch } from "react-redux";
import MainLayout from "./MainLayout";
const Home = React.lazy(() => import("../../pages/home/Home"));
const Tenders = React.lazy(() => import("../../pages/tenders/Tenders"));
const ArchivedTenders = React.lazy(() => import("../../pages/tenders/ArchivedTenders"));
const Operations = React.lazy(() => import("../../pages/tenders/Operations"));
const PropertyDevelopment = React.lazy(() => import("../../pages/tenders/PropertyDevelopment"));
const AboutUPMRC = React.lazy(() => import("../../pages/about/AboutUPMRC"));
const UPMRCTeam = React.lazy(() => import("../../pages/about/UPMRCTeam"));
const VisionMission = React.lazy(() => import("../../pages/about/VisionMission"));
const BoardOfDirectors = React.lazy(() => import("../../pages/about/BoardOfDirectors"));
const Certifications = React.lazy(() => import("../../pages/about/Certifications"));
const PioneerOfInnovations = React.lazy(() => import("../../pages/about/PioneerOfInnovations"));
const Content = React.lazy(() => import("../../pages/content/Content"));
const PressRelease = React.lazy(() => import("../../pages/content/PressRelease"));
const ContentDetail = React.lazy(() => import("../../components/content/ContentDetail"));
const Sitemap = React.lazy(() => import("../../pages/sitemap/Sitemap"));
const ScreenReaderAccess = React.lazy(() => import("../../pages/screenReader/ScreenReader"));
const Search = React.lazy(() => import("../../pages/search/Search"));
const ContactUs = React.lazy(() => import("../../pages/contactus/ContactUs"));
const ImageGallery = React.lazy(() => import("../../pages/imageGallery/ImageGallery"));
const ImageGalleryDetail = React.lazy(() => import("../../pages/imageGallery/ImageGalleryDetail"));
const ComingSoon = React.lazy(() => import("../../pages/comingSoon/ComingSoon"));
const NotFound = React.lazy(() => import("../../pages/404/NotFound"));
const GoSmartCard = React.lazy(() => import("../../pages/goSmartCard/GoSmartCard"));
const Deputation = React.lazy(() => import("../../pages/deputation/Deputation"));
const Login = React.lazy(() => import("../../components/layout/Login"));

const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dataActions.setCurrentCity(""))
    }, [window.location.hostname])

    return (
        <>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/:lang/login" element={<Login />} />
                <Route element={<MainLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/:lang" element={<Home />} />
                    <Route path="/tenders" element={<Tenders />} />
                    <Route path="/:lang/tenders" element={<Tenders />} />
                    <Route path="/tenders/:category/:subcategory" element={<Tenders />} />
                    <Route path="/:lang/tenders/:category/:subcategory" element={<Tenders />} />
                    <Route path="/operations" element={<Operations />} />
                    <Route path="/:lang/operations" element={<Operations />} />
                    <Route path="/operations/:category/:subcategory" element={<Operations />} />
                    <Route path="/:lang/operations/:category/:subcategory" element={<Operations />} />
                    <Route path="/property-development" element={<PropertyDevelopment />} />
                    <Route path="/:lang/property-development" element={<PropertyDevelopment />} />
                    <Route path="/property-development/:category/:subcategory" element={<PropertyDevelopment />} />
                    <Route path="/:lang/property-development/:category/:subcategory" element={<PropertyDevelopment />} />
                    <Route path="/archived-tenders" element={<ArchivedTenders />} />
                    <Route path="/:lang/archived-tenders" element={<ArchivedTenders />} />
                    <Route path="/archived-tenders/:category/:subcategory" element={<ArchivedTenders />} />
                    <Route path="/:lang/archived-tenders/:category/:subcategory" element={<ArchivedTenders />} />
                    <Route path="/about/about-upmrcl" element={<AboutUPMRC />} />
                    <Route path="/:lang/about/about-upmrcl" element={<AboutUPMRC />} />
                    <Route path="/about/upmrc-team" element={<UPMRCTeam />} />
                    <Route path="/:lang/about/upmrc-team" element={<UPMRCTeam />} />
                    <Route path="/about/vision-mission" element={<VisionMission />} />
                    <Route path="/:lang/about/vision-mission" element={<VisionMission />} />
                    <Route path="/about/board-of-directors" element={<BoardOfDirectors />} />
                    <Route path="/:lang/about/board-of-directors" element={<BoardOfDirectors />} />
                    <Route path="/about/certifications" element={<Certifications />} />
                    <Route path="/:lang/about/certifications" element={<Certifications />} />
                    <Route path="/about/pioneer-of-innovations" element={<PioneerOfInnovations />} />
                    <Route path="/:lang/about/pioneer-of-innovations" element={<PioneerOfInnovations />} />
                    <Route path="/site-map" element={<Sitemap />} />
                    <Route path="/:lang/site-map" element={<Sitemap />} />
                    <Route path="/screen-reader-access" element={<ScreenReaderAccess />} />
                    <Route path="/:lang/screen-reader-access" element={<ScreenReaderAccess />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/:lang/contact-us" element={<ContactUs />} />
                    <Route path="/image-gallery" element={<ImageGallery />} />
                    <Route path="/:lang/image-gallery" element={<ImageGallery />} />
                    <Route path="/image-gallery/:id" element={<ImageGalleryDetail />} />
                    <Route path="/:lang/image-gallery/:id" element={<ImageGalleryDetail />} />
                    <Route path="/pages/press-releases" element={<PressRelease />} />
                    <Route path="/:lang/pages/press-releases" element={<PressRelease />} />
                    <Route path="/pages/press-releases/:slug" element={<ContentDetail />} />
                    <Route path="/:lang/pages/press-releases/:slug" element={<ContentDetail />} />
                    <Route path="/pages/:slug" element={<Content />} />
                    <Route path="/:lang/pages/:slug" element={<Content />} />
                    <Route path="/search/:search" element={<Search />} />
                    <Route path="/:lang/search/:search" element={<Search />} />
                    <Route path="/smart-card" element={<GoSmartCard />} />
                    <Route path="/:lang/smart-card" element={<GoSmartCard />} />
                    <Route path="/careers/deputation" element={<Deputation />} />
                    <Route path="/:lang/careers/deputation" element={<Deputation />} />
                    <Route path="/careers/new-recruitments" element={<Deputation />} />
                    <Route path="/:lang/careers/new-recruitments" element={<Deputation />} />
                    <Route path="/careers/corrigendum-for-recruitments" element={<Deputation />} />
                    <Route path="/:lang/careers/corrigendum-for-recruitments" element={<Deputation />} />
                    <Route path="/careers/results" element={<Deputation />} />
                    <Route path="/:lang/careers/results" element={<Deputation />} />
                    <Route path="/coming-soon" element={<ComingSoon />} />
                    <Route path="/:lang/coming-soon" element={<ComingSoon />} />
                    <Route path="/:lang/404" element={<NotFound />} />
                    <Route path="/404" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
};

export default Main;