/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { CloseBtn, Download, GroupClose, RemoveMore, Upload } from "../svg/AllSvg";
import { dataActions } from "../../shared/store/data-Slice";
import SelectSecondaryComponent from "../form/SelectSecondaryComponent";
import { useEffect, useState } from "react";
import { url } from "../../shared/utils/url";
import useAxios from "../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";
import { Form, Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";

let proof: any[] = [
    { label: "Aadhar Card", value: 1 },
    { label: "Voter ID Card", value: 2 },
    { label: "Driving License", value: 3 },
    { label: "Passport", value: 4 }
]

let category: any[] = [
    { label: "Electronic Item", value: 1 },
    { label: "Cash", value: 2 },
    { label: "Valuable Good", value: 3 },
]

const LostFoundForm = () => {
    const lostFound: boolean = useSelector((state: any) => state.data.lostFound);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const cities: any = useSelector((state: any) => state.data.cities);
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const [stations, setStations] = useState<any[]>([]);
    const [stOpen, setStOpen] = useState<any>(false);
    const [proofOpen, setProofOpen] = useState<any>(false);
    const [categoryOpen, setCategoryOpen] = useState<any>(false);
    let [stPh, setStPh] = useState<any>(t("city.lost_found.report_lost_item_form.form.placeholder.station"));
    const [station, setStation] = useState<any>(null)
    const [stSearch, setStSearch] = useState<any>("");
    const [files, setFiles] = useState<any>([]);
    const [file, setFile] = useState<any>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [disable, setDisable] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);

    useEffect(() => {
        (!!!station && !!!stSearch) && setStPh(t("city.lost_found.report_lost_item_form.form.placeholder.station"));
    }, [station, stSearch, lang])

    const reportObj: any = {
        name: "",
        phone: "",
        email: "",
        proof: "",
        doc_id: "",
        category: "",
        item_name: "",
        qty: "",
        station: "",
        date: "",
        desc: ""
    };

    const reportSchema = yup.object().shape({
        name: yup.string().required(t("city.lost_found.report_lost_item_form.form.validation.name")),
        phone: yup.string().matches(/^\d{10}$/, t("city.lost_found.report_lost_item_form.form.validation.wrong_phone")).required(t("city.lost_found.report_lost_item_form.form.validation.phone")),
        email: yup.string().email(t("city.lost_found.report_lost_item_form.form.validation.wrong_email")),
        proof: yup.object().required(t("city.lost_found.report_lost_item_form.form.validation.proof")),
        doc_id: yup.string(),
        category: yup.object().required(t("city.lost_found.report_lost_item_form.form.validation.category")),
        desc: yup.string(),
        date: yup.date().required(t("city.lost_found.report_lost_item_form.form.validation.date")),
        item_name: yup.string().required(t("city.lost_found.report_lost_item_form.form.validation.item_name")),
        qty: yup.number(),
        station: yup.object().required(t("city.lost_found.report_lost_item_form.form.validation.station"))
    });


    const getStations = async () => {
        try {
            const res: any = await useAxios.get(`/${lang + url?.searchStation + cities[currentCity]}/${stSearch}/`);

            if (res && res?.status === 200) {
                const arr: any[] = res?.data?.length > 0 ? Array.isArray(res?.data) && res?.data?.map((item: any) => ({ label: item?.st_name, value: item?.st_code })) : [];
                setStations(arr)
            }
        } catch (error) {
            console.error("error while feching stations", error);
        }
    }

    useEffect(() => {
        (!!lang && !!stSearch) && getStations();
    }, [lang, stSearch])

    useEffect(() => {
        stSearch?.length === 0 && setStations([])
    }, [stSearch])

    const handleInputChanged = (input: any, reason: any) => {
        if (
            reason.action === "set-value" ||
            reason.action === "input-blur" ||
            reason.action === "menu-close"
        ) {
            return;
        }
        setStSearch(input);
        input !== station?.label && setStation(null);
    };

    const simulateChunkUpload = (chunk: any) => {
        // console.log(chunk, "1MB")
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            if (files?.length === 1 || acceptedFiles?.length > 1) {
                return;
            } else {
                setUploadProgress(0);
                setDisable(true)
                setFiles([...files, { file: acceptedFiles, url: URL.createObjectURL(acceptedFiles[0]) }]);
                setFile([...file, acceptedFiles[0]])

                let uploadFile = acceptedFiles[0];
                if (uploadFile) {
                    const chunkSize = 1024 * 1024; // 1MB chunk size
                    const totalChunks = Math.ceil(uploadFile.size / chunkSize);
                    let currentChunk = 0;

                    const uploadNextChunk = () => {
                        const start = currentChunk * chunkSize;
                        const end = Math.min(start + chunkSize, uploadFile.size);
                        const chunk = uploadFile.slice(start, end);

                        simulateChunkUpload(chunk)
                            .then(() => {
                                currentChunk++;

                                if (currentChunk < totalChunks) {
                                    const progress = Math.floor((currentChunk / totalChunks) * 100);
                                    setUploadProgress(progress);

                                    uploadNextChunk();
                                } else {
                                    setUploadProgress(100);
                                    setDisable(false)
                                }
                            })
                            .catch((error) => {
                                console.error('Error uploading chunk', error);
                            });
                    };

                    uploadNextChunk();
                }
            }
        }
    });

    const removeFile = (idx: number) => {
        let arr: any[] = [];
        for (let i = 0; i < files?.length; i++) {
            const el = files[i];

            if (idx !== i) {
                arr.push(el)
            }
        }
        setFiles(arr);
    };

    const thumbs = files.map((file: any, idx: number) => (
        <div key={idx} className="border border-[#D0D0D0] rounded-[10px] h-[40px] flex flex-col items-center w-full relative bg-white shadow-[0px_8px_8px_-4px_rgba(24,39,75,0.08),0px_4px_6px_-4px_rgba(24,39,75,0.12)]">
            <div className='w-full flex items-center justify-between h-full px-[19px]'>
                <h3 className="text-[#4B5563] text-xs sm:text-sm font-normal truncate">{file?.file[0]?.name}</h3>

                {(uploadProgress !== 100) ?
                    <>
                        {(idx + 1 === files?.length) && <div className="flex items-center gap-[10px]">
                            <h1 className="text-primaryLight text-sm font-normal">Uploading {uploadProgress}%</h1>
                            <div className="cursor-pointer" onClick={() => removeFile(idx)}>
                                <GroupClose />
                            </div>
                        </div>}
                    </> :
                    <div className="flex items-center gap-[10px]">
                        <Link title="Download" aria-label="Download" to={file?.url} download={file?.url} target="_blank" className="cursor-pointer">
                            <Download />
                        </Link>
                        <div aria-label="Remove" title="Remove" className="cursor-pointer" onClick={() => removeFile(idx)}>
                            <RemoveMore />
                        </div>
                    </div>
                }
            </div>
        </div>
    ));

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setLostFound(false))
        }
    };

    const handleSubmit = async (values: any) => { }

    return (
        <>
            {lostFound && <div className="fixed inset-0 z-40 top-0 w-full h-full overflow-y-auto bg-[rgba(203,203,203,0.40)] backdrop-blur-[7.5px]">
                <div className="flex justify-center items-center lg:min-h-screen">
                    <div className="relative my-6 md:mx-auto bg-white rounded-[20px] flex flex-col lg:flex-row w-[320px] sm:w-[600px] md:w-[745px] lg:w-[980px] h-full shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]">
                        <div className="w-full h-[250px] lg:h-auto lg:w-[427px] lg:max-h-full relative">
                            <img src={require("../../assets/images/lost-found.png")} alt="report-lost-item-bg" className="w-full h-[250px] lg:h-full object-cover rounded-t-[20px] lg:rounded-r-none lg:rounded-l-[20px] " />
                            <div style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 78%)" }} className="absolute top-0 right-0 bottom-0 left-0 w-full lg:max-h-full rounded-t-[20px] lg:rounded-r-none lg:rounded-l-[20px] h-[250px] lg:h-auto"></div>

                            <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col items-start justify-end lg:max-h-full p-[20px] lg:p-[41px] gap-[14px] h-[250px] lg:h-auto">
                                <h1 className="font-caudex text-white text-2xl sm:text-3xl 1xl:text-[2.5rem] sm:leading-[43.2px]">{t("city.lost_found.report_lost_item_form.title")}</h1>
                                <p className="text-[#DBDBDB] text-xs font-normal">{t("city.lost_found.report_lost_item_form.desc")}</p>
                            </div>
                        </div>

                        <div className="flex flex-col relative w-full lg:w-[calc(100%-427px)] p-[35px]">
                            <div className="cursor-pointer w-full flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]" onClick={() => dispatch(dataActions.setLostFound(false))}>
                                <CloseBtn />
                            </div>

                            <Formik
                                initialValues={reportObj}
                                validationSchema={reportSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <div className="flex flex-col">
                                            <h2 className="text-[#32264D] text-sm font-bold border-b border-[#E6E6F0] pb-[4px]">{t("city.lost_found.report_lost_item_form.form.personal_info")}</h2>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[15px] pt-[10px]">
                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="first_name">{t("city.lost_found.report_lost_item_form.form.field.name")}</label>
                                                    <input autoComplete="off" className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[40px] px-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal" type="text" name="name" value={values?.name} onBlur={handleBlur} onChange={handleChange} />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="text-red-500 text-xs mb-[-16px]"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="email">{t("city.lost_found.report_lost_item_form.form.field.email")}</label>
                                                    <input autoComplete="off" className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[40px] px-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal" type="email" name="email" value={values?.email} onBlur={handleBlur} onChange={handleChange} />
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="phone_number">{t("city.lost_found.report_lost_item_form.form.field.phone")}</label>
                                                    <input autoComplete="off" className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[40px] px-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal" type="text" name="phone" value={values?.phone} onBlur={handleBlur} onChange={handleChange} />
                                                    <ErrorMessage
                                                        name="phone"
                                                        component="div"
                                                        className="text-red-500 text-xs mb-[-16px]"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="doc_id">{t("city.lost_found.report_lost_item_form.form.field.doc_id")}</label>
                                                    <input autoComplete="off" className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[40px] px-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal" type="text" name="doc_id" value={values?.doc_id} onBlur={handleBlur} onChange={handleChange} />
                                                </div>

                                                <div className="flex flex-col gap-[4px] sm:col-span-2">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="id_proof_type">{t("city.lost_found.report_lost_item_form.form.field.proof")}</label>
                                                    <SelectSecondaryComponent
                                                        options={proof}
                                                        name="proof"
                                                        placeholder={t("city.lost_found.report_lost_item_form.form.placeholder.proof")}
                                                        isSearchable={false}
                                                        value={values?.proof}
                                                        onBlur={(e: any) => { setFieldTouched("proof", e); setProofOpen(false); }}
                                                        onChange={(e: any) => { setFieldValue("proof", e); setProofOpen(false); }}
                                                        menuIsOpen={proofOpen}
                                                        onMenuOpen={() => setProofOpen(true)}
                                                        onMenuClose={() => setProofOpen(false)}
                                                        arrow={true}
                                                    />
                                                    <ErrorMessage
                                                        name="proof"
                                                        component="div"
                                                        className="text-red-500 text-xs mb-[-16px]"
                                                    />
                                                </div>

                                                <div className="rounded-[8px] border border-dashed border-[#ABBEFF] bg-[#F4F4F9] flex items-center justify-center gap-[4px] min-h-[75px] sm:col-span-2 cursor-pointer" {...getRootProps()} onClick={(e) => e.stopPropagation}>
                                                    {(uploadProgress === 0 || uploadProgress === 100) && <>
                                                        <input autoComplete="off" {...getInputProps()} />
                                                        <div className="cursor-pointer" onClick={open}>
                                                            <Upload />
                                                        </div>
                                                    </>}
                                                    <div className="flex flex-col max-w-[172px]">
                                                        <h5 className="text-[#806BFF] text-[0.625rem] font-bold pl-[4px]">{t("city.lost_found.report_lost_item_form.form.field.file")}</h5>
                                                        <p className="text-center text-[rgba(0,0,0,0.40)] font-bold text-[0.625rem]">{t("city.lost_found.report_lost_item_form.form.field.file_desc")}</p>
                                                    </div>
                                                </div>

                                                <aside className='w-full flex flex-col gap-[12px] sm:col-span-2'>{thumbs}</aside>
                                            </div>
                                        </div>

                                        <div className="flex flex-col pt-[20px]">
                                            <h2 className="text-[#32264D] text-sm font-bold border-b border-[#E6E6F0] pb-[4px]">{t("city.lost_found.report_lost_item_form.form.lost_item")}</h2>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[15px] pt-[10px]">
                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="category">{t("city.lost_found.report_lost_item_form.form.field.category")}</label>
                                                    <SelectSecondaryComponent
                                                        options={category}
                                                        name="category"
                                                        placeholder={t("city.lost_found.report_lost_item_form.form.placeholder.category")}
                                                        isSearchable={false}
                                                        value={values?.category}
                                                        onBlur={(e: any) => { setFieldTouched("category", e); setCategoryOpen(false); }}
                                                        onChange={(e: any) => { setFieldValue("category", e); setCategoryOpen(false); }}
                                                        menuIsOpen={categoryOpen}
                                                        onMenuOpen={() => setCategoryOpen(true)}
                                                        onMenuClose={() => setCategoryOpen(false)}
                                                        arrow={true}
                                                    />
                                                    <ErrorMessage
                                                        name="category"
                                                        component="div"
                                                        className="text-red-500 text-xs mb-[-16px]"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="item_name">{t("city.lost_found.report_lost_item_form.form.field.item_name")}</label>
                                                    <input autoComplete="off" className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[40px] px-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal" type="text" name="item_name" value={values?.item_name} onBlur={handleBlur} onChange={handleChange}
                                                     />
                                                    <ErrorMessage
                                                        name="item_name"
                                                        component="div"
                                                        className="text-red-500 text-xs mb-[-16px]"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="qty">{t("city.lost_found.report_lost_item_form.form.field.qty")}</label>
                                                    <input autoComplete="off" className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[40px] px-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal" type="number" onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })} name="qty" value={values?.qty} onBlur={handleBlur} onChange={handleChange} />
                                                </div>

                                                <div className="flex flex-col gap-[4px]">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="date_of_lost">{t("city.lost_found.report_lost_item_form.form.field.date")}</label>
                                                    <input autoComplete="off" min={new Date().toISOString().split("T")[0]} className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[40px] px-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal" type="date" name="date" value={values?.date} onBlur={handleBlur} onChange={handleChange} />
                                                    <ErrorMessage
                                                        name="date"
                                                        component="div"
                                                        className="text-red-500 text-xs mb-[-16px]"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-[4px] sm:col-span-2">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="station">{t("city.lost_found.report_lost_item_form.form.field.station")}</label>
                                                    <SelectSecondaryComponent
                                                        options={stations}
                                                        name="station"
                                                        placeholder={stSearch || stPh}
                                                        menuIsOpen={stOpen}
                                                        onMenuOpen={() => setStOpen(true)}
                                                        onMenuClose={() => { setStOpen(false); !!!stSearch && setStPh(t("city.lost_found.report_lost_item_form.form.placeholder.station")) }}
                                                        onFocus={() => setStPh("")}
                                                        value={values?.station}
                                                        onBlur={(e: any) => { setFieldTouched("station", e); setProofOpen(false); }}
                                                        onChange={(e: any) => {
                                                            setFieldValue("station", e);
                                                            setStOpen(false);
                                                            setStSearch(e?.label);
                                                        }}
                                                        inputValue={stSearch}
                                                        onInputChange={handleInputChanged}
                                                        arrow={false}
                                                    />
                                                    <ErrorMessage
                                                        name="station"
                                                        component="div"
                                                        className="text-red-500 text-xs mb-[-16px]"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-[4px] sm:col-span-2">
                                                    <label className="text-[#4E4958] text-xs font-normal" htmlFor="desc">{t("city.lost_found.report_lost_item_form.form.field.desc")}</label>
                                                    <textarea rows={3} className="rounded-[7px] bg-[#F4F4F9] border border-[#ECECEC] h-[67px] p-[10px] focus-within:outline-none focus:outline-none text-secondaryText text-sm font-normal resize-none py-[8px]" name="desc" value={values?.desc} onBlur={handleBlur} onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-end justify-end w-full pt-[15px]">
                                            <button className="rounded-[10px] border border-[rgba(97,9,90,0.40)] h-[40px] w-[139px] shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center text-white text-sm font-bold bg-primary mt-[16px] disabled:opacity-[0.3] disabled:cursor-not-allowed focus-within:outline-none focus:outline-none" disabled={(!disable && !submit && !!values?.name && !!values?.phone && !!values?.proof && file?.length > 0 && !!values?.category && !!values?.item_name && !!values?.station && !!values?.date) ? false : true} >{t("city.lost_found.report_lost_item_form.form.btn")}</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default LostFoundForm;