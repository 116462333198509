/* eslint-disable react-hooks/exhaustive-deps */
import { CloseBtn, HeartBadge } from "../svg/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../shared/store/data-Slice";
import { useEffect, useRef, useState } from "react";
import FeedbackCard from "./FeedbackCard";
import { useTranslation } from "react-i18next";

const FeedbackForm = () => {
	const [success, setSuccess] = useState(false);
	const feedbackOpen: boolean = useSelector((state: any) => state.data.feedbackOpen);
	const registerOpen: boolean = useSelector((state: any) => state.data.registerOpen);
	const loginOpen: boolean = useSelector((state: any) => state.data.loginOpen);
	const loginWarnOpen: boolean = useSelector((state: any) => state.data.loginWarnOpen);
	const dispatch = useDispatch();
	const { t } = useTranslation('common');
	const ref1: any = useRef();

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref1.current && !ref1.current.contains(event.target)) {
				if (!registerOpen && !loginOpen && !loginWarnOpen) {
					dispatch(dataActions.setFeedbackOpen(false))
				}
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref1]);

	document.onkeydown = function (evt: any) {
		if (evt.keyCode === 27) {
			dispatch(dataActions.setFeedbackOpen(false))
		}
	};

	return (
		<>
			{feedbackOpen && <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(203,203,203,0.40)] backdrop-blur-[7.5px]">
				{success ?
					<>
						<div
							className="fixed inset-0 w-full h-full bg-[rgba(88,88,88,0.40)] backdrop-blur-[2.5px]" onClick={() => dispatch(dataActions.setFeedbackOpen(false))}
						></div>
						<div className="flex justify-center items-center lg:min-h-screen">
							<div className="relative my-6 md:mx-auto bg-white rounded-[20px] w-[320px] sm:w-[600px] md:w-[745px] lg:w-[600px] h-full shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]">
								<button className="cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px] focus:outline-none focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => dispatch(dataActions.setFeedbackOpen(false))}>
									<CloseBtn />
								</button>
								<div className="flex flex-col items-center justify-center pl-[50px] pr-[50px] pt-[40px] pb-[40px]">
									<HeartBadge />
									<h1 className="text-2xl mt-[40px] text-[#61095A] font-bold">{t("contact_us.success.title")}</h1>
									<h2 className="text-m text-center mt-[20px] text-[#6D6D6D]">{t("contact_us.success.subtitle")}</h2>
									<h2 className="text-m text-center mt-[20px] text-[#6D6D6D]">{t("contact_us.success.thank_you")}</h2>
								</div>
							</div>
						</div>
					</>
					:
					<>
						<div className="flex justify-center items-center lg:min-h-screen">
							<div className="my-6 md:mx-auto bg-white rounded-[20px] flex flex-col lg:flex-row w-[320px] sm:w-[600px] md:w-[745px] lg:w-[980px] h-full shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)] relative" ref={ref1}>
								<FeedbackCard setSuccess={setSuccess} popup={true} />
							</div>
						</div>
					</>
				}
			</div>
			}
		</>
	);
};

export default FeedbackForm;