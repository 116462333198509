import { useTranslation } from "react-i18next";
import { CloseBtn, SearchJourney } from "../svg/AllSvg";

const SearchOpen = ({ setSearchOpen, searchOpen, handleSearch, handleInput, search, setSearch }: any) => {
    const { t } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            setSearchOpen(false)
        }
    };

    return (
        <>
            {searchOpen &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(88,88,88,0.40)] backdrop-blur-[2.5px]" onClick={() => setSearchOpen(false)}
                    ></div>
                    <div className="flex justify-center items-start min-h-screen">
                        <div className="relative m-[24px] md:mt-[80px] md:mx-auto bg-white rounded-[20px] w-full sm:w-[515px] h-full shadow-[0px_14.134px_69.101px_-3.141px_rgba(24,39,75,0.14),0px_6.282px_21.987px_-4.711px_rgba(24,39,75,0.12)]">
                            <button className="cursor-pointer flex items-end justify-end opacity-[0.3] absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px] focus:outline-none focus:shadow-[0_0_6px_0px_#32435F] w-[19px] h-[19px] rounded-full" autoFocus={true} onClick={() => setSearchOpen(false)}>
                                <CloseBtn />
                            </button>

                            <div className="p-[20px] w-full pt-[50px]">
                                <div className="rounded-full bg-grey h-[45px] w-full flex items-center gap-[4px] border pl-[15px] pr-[4px]">
                                    <input type="text" className="focus:outline-none focus-within:outline-none text-secondary dark:!text-secondary placeholder:text-secondary placeholder:dark:!text-secondary text-[14px] w-full font-normal h-full bg-transparent" autoFocus placeholder={t("main_header.search")} value={search} onChange={(e: any) => setSearch(e.target.value)} onKeyDown={(e) => handleInput(e)} autoComplete="off" />
                                    <button className="bg-primary min-w-[38px] h-[38px] flex items-center justify-center rounded-full" onClick={handleSearch}>
                                        <SearchJourney />
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default SearchOpen;