import axios from "axios";
import { url } from "../utils/url";

const useAxios = axios.create({
    baseURL: url?.baseUrl
});

const requestHandler = (req: any): any => {
    if (localStorage.getItem('token')) {
        // eslint-disable-next-line
        req.headers['Authorization'] = 'Token' + ' ' + localStorage.getItem('token');
    }
    return req;
};

const responseHandler = (res: any): any => {
    return res;
};

const errorHandler = (error: any): any => {
    return Promise.reject(error);
};

useAxios.interceptors.request.use(
    (req: any) => requestHandler(req),
    (error: any) => errorHandler(error)
);

useAxios.interceptors.response.use(
    (res: any) => responseHandler(res),
    (error: any) => errorHandler(error)
);

export default useAxios;