import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    isLogin: boolean,
    user: any
}

const initialState: AuthState = {
    isLogin: !!JSON.parse(localStorage.getItem("sessionTimeout") as any) ? true : false,
    user: JSON.parse(localStorage.getItem('userdetail') as any) || null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.isLogin = true;
        },
        logout: (state) => {
            state.isLogin = false;
            localStorage.removeItem("sessionTimeout");
            localStorage.removeItem("userdetail");
            localStorage.removeItem("token");

        },
        userDetail: (state, action) => {
            state.user = action.payload;
        }
    }
});

export default authSlice;
export const authActions = authSlice.actions;